.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:focus {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #422918;
}
