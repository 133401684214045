th,
td {
  padding: 0.5em 1em !important;
}
thead tr:first-child th:first-child {
  border-radius: 0.6em 0 0 0 !important;
}
thead tr:first-child th:last-child {
  border-radius: 0 0.6em 0 0 !important;
}
tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.6em !important;
}
tbody tr:last-child td:last-child {
  border-radius: 0 0 0.6em 0 !important;
}
.table > tbody > tr > td,
.table > thead > tr > th {
  border: 0 !important;
}
